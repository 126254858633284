import {
  Transaction,
  Address,
  TransactionPayload,
  TransactionVersion,
} from '@elrondnetwork/erdjs';
import {
  contractAddress, gasPrice, version
} from 'config';
import { RawTransactionType } from 'helpers/types';
import { customNetworkConfig } from '../../config';
import { ESDT_TRANSFER_GAS_LIMIT } from '@elrondnetwork/erdjs/out/constants';

export default function useNewTransaction() {
  const { chainId } = customNetworkConfig;
  return (rawTransaction: RawTransactionType) => {
    const gasLimit = rawTransaction.gasLimit ? rawTransaction.gasLimit : ESDT_TRANSFER_GAS_LIMIT;
    const price = Number.parseFloat(rawTransaction.value) * Math.pow(10, 18);
    // if(rawTransaction.valuz === 3){
    //   price = 4350000000000000000;

    // }
    // if(rawTransaction.valuz === 6){
    //   price = 8700000000000000000;

    // }
    // console.log(price);
    return new Transaction({
      value: price.toString(),
      data: new TransactionPayload(rawTransaction.data),
      receiver: new Address(rawTransaction.receiver),
      gasLimit,
      gasPrice: gasPrice,
      chainID: chainId,
      version: new TransactionVersion(version),
    });
  };
}

