import React from "react";
import { Navbar as BsNavbar, NavItem, Nav } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as ElrondLogo } from "./../../../assets/img/logo.svg";
import { logout, useGetAccountInfo } from '@elrondnetwork/dapp-core';

const Navbar = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const history = useHistory();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    void logout(`${window.location.origin}/`);
    history.push("/");
  };

  return (
    <BsNavbar className="bg-white border-bottom px-4 py-3">
      <div className="container-fluid">
        <Link
          className="d-flex align-items-center navbar-brand mr-0"
          to={isLoggedIn ? "/dashboard" : "/"}
        >
          <ElrondLogo className="elrond-logo" />
          {/* <span className="dapp-name text-muted">{dAppName}</span> */}
        </Link>

        <Nav className="ml-auto">
          {isLoggedIn && (
            <NavItem>
              <a href="/" onClick={logOut}>
                Log out
              </a>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
