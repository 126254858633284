import * as React from "react";
import {useEffect} from "react";
import Actions from "./Actions";
import { useGetAccountInfo, sendTransactions, refreshAccount } from '@elrondnetwork/dapp-core';
import { Route, Switch, useLocation} from 'react-router-dom';
import StatusIcon from "./Transactions/StatusIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const networks={
  "Elrond": "https://api.elrond.com",
  "Elrond-Testnet": "https://testnet-api.elrond.com",
};
const url = "https://fungyy-gate.tophatturtle.in";

const Dashboard = () => {
  const ref = React.useRef(null);
  const { search } = useLocation();
  
  const query = new URLSearchParams(search);
  const { gateId, nftId, src, blockchain, variant } = Object.fromEntries(query);
  
  const account = useGetAccountInfo();
  const { address } = account;
  const [errorMessage, setErrorMessage] = React.useState(null);


  
  useEffect(()=>{
    if(src=="nftaccess"){
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        `${networks[blockchain]}/nfts/${nftId}`,
        requestOptions
      ).then(r=>{
        return r.json();
      }).then(r=>{
        console.log(r);
        if(r.owner == address){
          return fetch(
            `${url}/open/getDraftOrder?` +
            new URLSearchParams({
              gateId: gateId,
              variant: variant,
              account: address,
            }),
            requestOptions
          );
        }
        else{
          setErrorMessage("Sorry, but Your account does not hold the NFT. \n You may close the window now and try with a different wallet.");
          throw new Error("Does not worn");
        }
      
      }).then(r=>{
        return r.json();
      }).then(r=>{
        if (r.invoice_url) {
          window.location.href = r.invoice_url;
        }else{
          setErrorMessage("Sorry, but Your account does not hold the NFT. \n You may close the window now and try with a different wallet.");
          throw new Error("Failed");
        }
      }).catch(err=>{
        console.log(err);
      });
    }
  },[]);
  if(errorMessage != null && src=="nftaccess"){
    return <div className="center" ><h3 className="nft-access-loading">{errorMessage}</h3></div>;
  }
  else if(src=="nftaccess")
    return <h3 className="nft-access-loading">Checking eligibility, please wait...</h3>;
  else
    return <Actions />;
};

export default Dashboard;
