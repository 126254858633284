import {
  Transaction,
  Address,
  TransactionPayload,
  TransactionVersion,
} from '@elrondnetwork/erdjs';
import {
  gasPrice,
  version,
} from 'config';
import { RawTransactionType } from 'helpers/types';
import { customNetworkConfig } from '../../config';
import { ESDT_TRANSFER_GAS_LIMIT } from '@elrondnetwork/erdjs/out/constants';

export default function useNewTransactionRaw() {
  const { chainId } = customNetworkConfig;

  return (rawTransaction: RawTransactionType) => {
    const gasLimit = rawTransaction.gasLimit ? rawTransaction.gasLimit : ESDT_TRANSFER_GAS_LIMIT;
    const price = Number.parseFloat(rawTransaction.value) * Math.pow(10, 18);

    return new Transaction({
      value: price.toString(),
      data: rawTransaction.payload,
      receiver: new Address(rawTransaction.receiver),
      gasLimit,
      gasPrice: gasPrice,
      chainID: chainId,
      version: new TransactionVersion(version),
    });
  };
}
