import * as React from 'react';
import {
  Address,
  AddressValue,
  ContractFunction,
  SmartContract,
  Query,
  TransactionPayload,
  U16Value,
  U64Value,
  U32Value,
  ArgSerializer,
} from '@elrondnetwork/erdjs';
import { Form, InputGroup } from 'react-bootstrap';
import Papa from "papaparse";
import {
  contractAddress,
  customNetworkConfig,
  nftCollectionIdentifier,
  nftCombeysCollectionIdentifier
} from 'config';
import { RawTransactionType } from 'helpers/types';
import useNewTransaction from 'pages/Transaction/useNewTransaction';
import { routeNames } from 'routes';
import useNewTransactionNonPayable from 'pages/Transaction/useNewNonPaybale';

import useNewTransactionRaw from 'pages/Transaction/useNewTransactionRaw';
import {
  useGetAccountInfo,
  sendTransactions,
  refreshAccount,
  transactionServices,
  useCheckTransactionStatus,
} from '@elrondnetwork/dapp-core';
import { getTransactions } from '../helpers/asyncRequests';
import { AlertDismissible, remove } from 'pages/Transaction';
import useNewTransactionFree from 'pages/Transaction/useNewTransactionFree';
import moment from 'moment';

const Actions = () => {
  const [referralResponseMessage, setReferralResponseMessage] =
    React.useState(null);
  const account = useGetAccountInfo();
  const { address } = account;
  const newTransaction = useNewTransaction();
  const newTransactionFree = useNewTransactionFree();
  const newRawTransaction = useNewTransactionRaw();
  const newTransactionNonPayable = useNewTransactionNonPayable();

  const [nftsRemaining, setNftsRemaining] = React.useState(0);
  const [mintingEnabled, setMintingEnabled] = React.useState(true);
  const [quantity, setQuantity] = React.useState(1);
  const [referralEnabled, setReferralEnabled] = React.useState(false);
  const [whitelist, setWhitelist] = React.useState(false);
  const [combeysInWallet, setCombeysInWallet] = React.useState(0);
  const [combotsInWallet, setCombotsInWallet] = React.useState(0);
  const [typedReferralCode, settypedReferralCode] = React.useState('');
  const [referralCodeError, setReferralCodeError] = React.useState(false);
  const /*transactionSessionId*/ [transSessionId, setTransactionSessionId] =
      React.useState<string | null>(null);

  const { success, fail, hasActiveTransactions } =
    transactionServices.useGetActiveTransactionsStatus();
  const [ranking, setRanking] = React.useState(NaN);

  const DROP_PRICE = 1.88;
  const DROP_PRICE_REFERRAL = 0.2;
  const REMAINING_LIMIT = 1;
  const referralCode = localStorage.getItem('referralCode');

  //can't change this value, nbecause too high of gas will occur
  const mintTransaction: RawTransactionType = {
    receiver: contractAddress,
    data: 'mint',
    value: `${DROP_PRICE}`,
    gasLimit: 600000000,
  };

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: transSessionId,
    async onSuccess(transactionId) {
      const addressFormatted = new Address(address).hex();
      console.log("formatted");
      console.log(addressFormatted);
      const how_many_minted = await fetch("https://gateway.elrond.com/vm-values/int", {
        body: JSON.stringify({
          "scAddress": "erd1qqqqqqqqqqqqqpgqa43cpj7tmjl2vef3dltx54c749azs94e6vvqsfyqp3",
          "funcName": "getNumbersOfMintForth",
          "args": [addressFormatted]
      }),
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer',
      }).then((response) => response.json());
      console.log(how_many_minted);
      console.log(how_many_minted);
      console.log(Number(how_many_minted.data.data));
      setCombotsInWallet(Number(how_many_minted.data.data));
      setTransactionSessionId(null);
      console.log(transactionStatus);
      console.log('transcation' + transactionStatus);
      const dataBase64 = transactionStatus.transactions[0].data;
      const dataInString = Buffer.from(dataBase64, 'base64').toString();
      console.log(dataInString);
      if (!dataInString.includes('get_refferal_unique_code')) {
        console.log('return');
        return;
      }
      const hash = transactionStatus.transactions[0].hash;

      const url = `${customNetworkConfig.apiAddress}/transactions/${hash}?withScResults=true`;
      const fromApi = await fetch(url).then((res) => res.json());
      try {
        console.log("fromapi");
        console.log(fromApi);
        const firstResult = fromApi.results[0];
        console.log(firstResult);
        const dataInBase64 = fromApi.results[0].data;

        const dataInStringHex = Buffer.from(dataInBase64, 'base64').toString();
        const onlyReferralPart = dataInStringHex.lastIndexOf('@');
        const onlyReffalarParts = remove(
          dataInStringHex,
          0,
          onlyReferralPart + 1,
        );

        let formattedHex = onlyReffalarParts;
        formattedHex = `0x${formattedHex}`;
        const dataInString = parseInt(formattedHex, 16);
        console.log(dataInString);
        setReferralResponseMessage(dataInString);
      } catch (e) {
        console.log(e);
      }
    },
    onFail(transactionId, errorMessage?) {
      setTransactionSessionId(null);
      console.log(transactionStatus);
      console.log('transcation' + transactionId + errorMessage);
    },
  });


  const getInfo = async () => {
    setMintingEnabled(false);
    const nftAmountUrl = `${customNetworkConfig.apiAddress}/accounts/${address}/nfts/count/?collection=${nftCombeysCollectionIdentifier}`;
    const urlTotalNfts = `${customNetworkConfig.apiAddress}/accounts/${contractAddress}/nfts/count`;
    const nftsAmount = fetch(nftAmountUrl);
    const data = await fetch(urlTotalNfts).then((res) => res.json());
    const nftsAmountSingle = await nftsAmount.then((res) => res.json());

    setCombeysInWallet(Number(nftsAmountSingle));

    //const combotsInWallet = await fetch(`${customNetworkConfig.apiAddress}/accounts/${address}/nfts/count/?collection=${nftCollectionIdentifier}`).then((res) => res.json());
  
    const addressFormatted = new Address(address).hex();
    console.log("formatted");
    console.log(addressFormatted);
    const how_many_minted = await fetch("https://gateway.elrond.com/vm-values/int", {
      body: JSON.stringify({
        "scAddress": "erd1qqqqqqqqqqqqqpgqa43cpj7tmjl2vef3dltx54c749azs94e6vvqsfyqp3",
        "funcName": "getNumbersOfMintForth",
        "args": [addressFormatted]
    }),
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
    }).then((response) => response.json());
    console.log(how_many_minted);
    console.log(how_many_minted);
    console.log(Number(how_many_minted.data.data));
    setCombotsInWallet(Number(how_many_minted.data.data));
    let remainingNfts = Number(data);
    // custom logic, just a prevention
    remainingNfts = remainingNfts - REMAINING_LIMIT;
    setNftsRemaining(remainingNfts);
    if (remainingNfts <= 0) {
      setMintingEnabled(false);
    } else {
      const now = moment();
const day = now.day();
const today1730 = moment('17:30','HH:mm');
const today1530 = moment('18:00','HH:mm');
const ok = now.isSameOrAfter(today1530);
      // if(ok){
      setMintingEnabled(true);
      // }
    }
    // setMintingEnabled(false);
    //setCombotsInWallet(combotsInWallet);
    // setMintingEnabled(false);

    console.log(ranking + 'ranking');
    const urlRankingData = `https://rarity-7gkbgtnhvq-ew.a.run.app/index/COMBEYS-bc640d/${address}`;
    console.log(urlRankingData);
    const rankingData = await fetch(urlRankingData, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
    }).then((response) => response.text());
    setRanking(parseFloat(rankingData));
    // setReferralResponseMessage("sjjdj");
    //setMintingEnabled(false);
   
  };

  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    console.log('referral code' + referralCode);
    if (referralCode !== 'undefined') {
      settypedReferralCode(referralCode);
    }

    Papa.parse("/addressest.csv", {
      download: true,
      header: true,
      complete: data => {
        console.log(data.data);
        console.log(account.address);
        const address = account.address.toString();
        setWhitelist(data.data.some(item => item.address === address));
        //setRows(data.data);
      }
    });

    Papa.parse("/referral.csv", {
      download: true,
      header: true,
      complete: data => {
        console.log(data.data);
        console.log(account.address);
        const address = account.address.toString();
        setReferralEnabled(data.data.some(item => item.address === address));
        //setRows(data.data);
      }
    });
    void getInfo();


  }, []);

  const fetchData = () => {
    console.log('fethcing' + success + fail + hasActiveTransactions);
    if (success || fail || !hasActiveTransactions) {
      console.log('active');
      getTransactions({
        apiAddress: customNetworkConfig.apiAddress,
        address: account.address,
        timeout: 3000,
        contractAddress,
      }).then(({ data, success: transactionsFetched }) => {
        refreshAccount();
        console.log(data);
        console.log('response');
        // setState({
        //   transactions: data,
        //   transactionsFetched
        // });
      });
    } else {
    }
  };

  // React.useEffect(fetchData, [success, fail, hasActiveTransactions]);
  // Transactions
  const send =
    (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      // if (typedReferralCode != '') {
      //   console.log("yes");
      //   let arg;
      //   try {
      //     arg = new U32Value(Number(typedReferralCode));
      //   } catch (e) {
      //     setReferralCodeError(true);
      //     return;
      //   }
      //   // with referral discount is always included.
      //   transaction.value = `${(quantity * DROP_PRICE_REFERRAL).toFixed(2)}`;
      //  // transaction.data = `buy_nft_with_code@0${quantity}`;
      //   transaction.payload = TransactionPayload.contractCall()
      //     .setFunction(new ContractFunction('buy_nft_with_code'))
      //     .addArg(arg)
      //     .addArg(new U16Value(quantity))
      //     .build();
        
      //   // console.log(transaction.payload.getEncodedArguments); 
      //   // console.log(transaction.payload.getRawArguments); 
      //   e.preventDefault();
      //   await refreshAccount();
      //   const { sessionId } = await sendTransactions({
      //     redirectAfterSign: false,
      //     transactions: newRawTransaction(transaction),
      //     // callbackRoute: routeNames.transaction,
      //     transactionDisplayInfo: {
      //       processingMessage: 'Processing transaction with referral code',
      //       errorMessage: 'An error has occured during send with referral code',
      //       successMessage: 'Send transaction with referral code successful',
      //     },
      //   });
      //   if (sessionId != null) {
      //     setTransactionSessionId(sessionId);
      //   }
      // } else {
        console.log((quantity * DROP_PRICE).toFixed(3));
        transaction.valuz = quantity;
        // if(quantity === 3){
        // transaction.value = `${4.35}`;
        // }
        // else{
        transaction.value = `${(quantity * DROP_PRICE).toFixed(2)}`;
       // }

        transaction.data = `mint@0${quantity}`;
        // if (combeysInWallet >= 1) {
        //   // getting discount code, since owns >1 but <10
        //   transaction.value = `${(quantity * DROP_PRICE_REFERRAL).toFixed(2)}`;
        //   transaction.data = `mint_with_owning_one_to_nine_nfts@0${quantity}`;
        // } else {
        //   transaction.value = `${(quantity * DROP_PRICE).toFixed(2)}`;
        //   transaction.data = `mint@0${quantity}`;
        // }

        e.preventDefault();
        await refreshAccount();

        console.log('started trans');

        const { sessionId } = await sendTransactions({
          transactions: newTransaction(transaction),
          redirectAfterSign: false,
          // callbackRoute: routeNames.transaction,
          transactionDisplayInfo: {
            processingMessage: 'Processing transaction',
            errorMessage: 'An error has occured during send',
            successMessage: 'Send transaction successful',
          },
        });
        console.log('finished' + sessionId);
        if (sessionId != null) {
          setTransactionSessionId(sessionId);
        }

        // console.log("transcation" + transactionStatus);
     // }
    };

  const getCode =
    (transaction: RawTransactionType) => async (e: React.MouseEvent) => {
      const addressFormatted = new Address(address).hex();
      console.log(address);
      transaction.data = `get_refferal_unique_code@${addressFormatted}`;
      e.preventDefault();
      const { sessionId } = await sendTransactions({
        transactions: newTransactionFree(transaction),
        // callbackRoute: routeNames.transaction,
        redirectAfterSign: false,
        transactionDisplayInfo: {
          processingMessage: 'Processing transaction',
          errorMessage: 'An error has occured during send',
          successMessage: 'Send transaction successful',
        },
      });
      if (sessionId != null) {
        setTransactionSessionId(sessionId);
      }
    };

  // Internal changes
  const referralCodeChange = (event) => {
    settypedReferralCode(event.target.value);
    setReferralCodeError(false);
  };

  const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const self = event.target as HTMLElement;

    if (self.id === 'minus') {
      if (quantity > 1) setQuantity(quantity - 1);
    } else if (self.id === 'plus') {
      if (quantity >= nftsRemaining) return;
      if (quantity < 7) setQuantity(quantity + 1);
    }
  };
  return (
    <div>
      {referralResponseMessage != null && (
        <AlertDismissible code={referralResponseMessage}></AlertDismissible>
      )}
      <div className="mint-container">
        {/* {!isNaN(ranking) ? (
          <h6 className="info-title">
            Your current rarity score is{' '}
            <span className="span-title">{ranking.toFixed(2)}</span>!
          </h6>
        ) : (
          <h6 className="info-title">
            <span className="span-title">Rarity score is loading...</span>
          </h6>
        )} */}
        {/* {
          referralEnabled? <button
          className="referral-code"
          onClick={getCode(referralCodeTranscation)}
        >
          Get referral link
        </button>:null
        } */}
        {/* <button
            className="referral-code"
            onClick={getCode(referralCodeTranscation)}
          >
            Get referral link
          </button> */}
        {/* {combeysInWallet >= 10 && referralCode === 'undefined' ? (
          <button
            className="referral-code"
            onClick={getCode(referralCodeTranscation)}
          >
            Get referral link
          </button>
        ) : null} */}
        {/* {referralCode === 'undefined' ? (
          <h6 className="info-title">USE REFERRAL CODE FOR 5% DISCOUNT! </h6>
        ) : null} */}
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <>
              {referralCode !== 'undefined' && referralCode !== null? (
                <div>
                {/* <h5 className="referral-code-title">
                  Referral code {referralCode} is applied
                </h5> */}
                {/* <button className="mint-btn" onClick={send(mintTransaction)}>
                  Mint {quantity} NFT
                </button> */}
                </div>

                
              ) : (
                <>
                </>
                // <InputGroup>
                //   <Form.Control
                //     type="text"
                //     placeholder="Enter code"
                //     onChange={referralCodeChange}
                //     isInvalid={referralCodeError}
                //   />
                //   <Form.Control.Feedback type="invalid">
                //     Referral code invalid.
                //   </Form.Control.Feedback>
                // </InputGroup>
              )
              }
            </>
          </Form.Group>
        </Form>

        {/* {combeysInWallet >= 1 ? (
          <h5 className="info-title">
            {DROP_PRICE_REFERRAL.toFixed(2)} EGLD for NFT
          </h5>
        ) : (
          <h5 className="info-title">{DROP_PRICE} EGLD for NFT</h5>
        )} */}
         <h5 className="info-title">{DROP_PRICE} EGLD for NFT</h5>
        {/* <h5 className="info-title">(5% donated to Ukraine 🇺🇦! )</h5> */}
        <div>
          <button className="change-qty" id="minus" onClick={handleChange}>
            -
          </button>
          {(() => {
            if (transSessionId) {
              return <button className="mint-btn">Mint in progress</button>;
            }
            // else if(whitelist==false){
            //   return <button className="mint-btn">Address not in WL</button>;
            // }
            // else if(combotsInWallet>=10){
            //   return <button className="mint-btn">NFTs limit reached</button>;
            // }
            
            else if (mintingEnabled) {
              return (
                <button className="mint-btn" onClick={send(mintTransaction)}>
                  Mint {quantity} NFT
                </button>
              );
            } else {
              return (
                <button className="mint-btn">No UGPs left</button>
              );
            }
          })()}
          <button className="change-qty" id="plus" onClick={handleChange}>
            +
          </button>
        </div>

        {(() => {
          if (nftsRemaining > 0) {
            return (
              <h5 className="info-title">{nftsRemaining} UGPs left!</h5>
            );
          } else {
            return <div></div>;
          }
        })()}
      </div>
    </div>
  );
};

export default Actions;

export const stringToHex = (str: string) => {
  if (str) {
    const arr1 = [];
    for (let n = 0, l = str.length; n < l; n++) {
      const hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join('');
  }
  return '';
};

export const hexToString = (strVal: string) => {
  if (strVal) {
    const hex = strVal.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  }
  return '';
};

export const base64ToHex = (str: string) => {
  const raw = atob(str);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : '0' + hex;
  }
  return result.toUpperCase();
};
