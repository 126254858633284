import {
  Transaction,
  Address,
  TransactionPayload,
  TransactionVersion,
} from '@elrondnetwork/erdjs';
import {
  gasPrice,
  version,
} from 'config';
import { RawTransactionType } from 'helpers/types';
import { customNetworkConfig } from '../../config';
import { ESDT_TRANSFER_GAS_LIMIT } from '@elrondnetwork/erdjs/out/constants';

export default function useNewTransactionNon() {
  const { chainId } = customNetworkConfig;

  return (rawTransaction: RawTransactionType) => {
    const gasLimit = rawTransaction.gasLimit ? rawTransaction.gasLimit
      : ESDT_TRANSFER_GAS_LIMIT;

    return new Transaction({
      value: null,
      data: new TransactionPayload(rawTransaction.data),
      receiver: new Address(rawTransaction.receiver),
      gasLimit,
      gasPrice: gasPrice,
      chainID: chainId,
      version: new TransactionVersion(version),
    });
  };
}
