import React from "react";
import routes, { routeNames } from "routes";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { AuthenticatedRoutesWrapper, refreshAccount, useGetAccountInfo } from '@elrondnetwork/dapp-core';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  React.useEffect(() => {
    if (isLoggedIn) {
      void refreshAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className="bg-light d-flex flex-column flex-fill wrapper">
      <Navbar />
      <main className="d-flex flex-column flex-grow-1">
        <AuthenticatedRoutesWrapper routes={routes} unlockRoute={routeNames.unlock}>
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
