import * as React from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, Link } from 'react-router-dom';
import PageState from 'components/PageState';
import { routeNames } from 'routes';
import {
  TransactionHash,
} from '@elrondnetwork/erdjs';
import { getProxyProvider, useGetNetworkConfig } from '@elrondnetwork/dapp-core';
import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { customNetworkConfig } from '../../config';

const Transaction = () => {
  const proxy = getProxyProvider();
  const { search } = useLocation();
  const { explorerAddress } = useGetNetworkConfig().network;

  const query = new URLSearchParams(search);
  const { status, txHash } = Object.fromEntries(query);
  const transactionHash = new TransactionHash(txHash);

  const [referralResponseMessage, setReferralResponseMessage] =
    React.useState(null);
  const [statusRender, setStatusRender] = React.useState(null);

  const initialFetch = async () => {
    const transactionFromSdk = await proxy.getTransaction(
      transactionHash,
      null,
      true,
    );
    setStatusRender(status);
    const url = `${customNetworkConfig.apiAddress}/transactions/${txHash}?withScResults=true`;
    const fromApi = await fetch(url).then((res) => res.json());
    try {
      const firstResult = fromApi.results[0];
      if (
        // checking if data continues get_refferal_unique_code so that we know from which call we entered here
        firstResult.data != null &&
        (transactionFromSdk.data
          .toString()
          .includes('get_refferal_unique_code') ||
          transactionFromSdk.data.toString().includes('buy_nft_with_code'))
      ) {
        if (fromApi.status == 'fail') {
          setReferralResponseMessage(fromApi.results[0].returnMessage);
        } else {
          // no need to display code after buying
          if (transactionFromSdk.data.toString().includes('buy_nft_with_code'))
            return;

          // doing formatting of message. TODO refactor
          const dataInBase64 = fromApi.results[0].data;

          const dataInStringHex = Buffer.from(
            dataInBase64,
            'base64',
          ).toString();
          const onlyReferralPart = dataInStringHex.lastIndexOf('@');
          const onlyReffalarParts = remove(
            dataInStringHex,
            0,
            onlyReferralPart + 1,
          );

          let formattedHex = onlyReffalarParts;
          formattedHex = `0x${formattedHex}`;
          const dataInString = parseInt(formattedHex, 16);
          setReferralResponseMessage(dataInString);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    console.log('oppned');

    void initialFetch();
  }, []);

  return statusRender != null ? (
    status === 'success' ? (
      <>
        {referralResponseMessage != null && (
          <AlertDismissible code={referralResponseMessage}></AlertDismissible>
        )}
        <PageState
          svgComponent={
            <FontAwesomeIcon icon={faCheck} className="text-success fa-3x" />
          }
          className="dapp-icon icon-medium"
          title="Transaction submitted successfully"
          description={
            <>
              <p>
                <a
                  href={`${explorerAddress}transactions/${txHash}`}
                  {...{
                    target: '_blank',
                  }}
                  className="tx-link"
                  title="View in Explorer"
                >
                  {txHash}
                </a>
              </p>
              <Link to={routeNames.dashboard} className="btn btn-primary mt-3">
                Back to dashboard
              </Link>
            </>
          }
        />
      </>
    ) : (
      <>
        <PageState
          svgComponent={
            <FontAwesomeIcon icon={faTimes} className="text-danger fa-3x" />
          }
          className="dapp-icon icon-medium"
          title="Error sending transaction"
          description={
            <>
              <p>Try again</p>
              <p>{referralResponseMessage}</p>
              <a href={routeNames.dashboard} className="btn btn-primary mt-3">
                Back to dashboard
              </a>
            </>
          }
        />
      </>
    )
  ) : null;
};

export default Transaction;

export function AlertDismissible(props) {
  const [show, setShow] = useState(true);

  return (
    <>
      <Alert show={show} variant="success">
        <Alert.Heading>A referral code is created!</Alert.Heading>
        <p>Your referral code ({props.code}) is ready!</p>
        <p>
          Your referral link is{' '}
          <a
            href={`https://nfts.combased.io/?referralCode=${props.code}`}
            className="link-primary"
          >{`https://nfts.combased.io/?referralCode=${props.code}`}</a>{' '}
          !
        </p>
        <hr />
        <div className="d-flex justify-content-end"></div>
      </Alert>

      {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>}
    </>
  );
}
export function remove(string, from, to) {
  return string.substring(0, from) + string.substring(to);
}
