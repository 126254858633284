import React from 'react';
import { DappUI, DappProvider } from '@elrondnetwork/dapp-core';
import { Route, Switch, useLocation} from 'react-router-dom';
import Layout from './components/Layout';
import PageNotFoud from './components/PageNotFoud';
import { customNetworkConfig, dAppName } from './config';
import { ContextProvider } from './context';
import routes, { routeNames } from './routes';
const {
  DappCorePages: { UnlockPage },
  SignTransactionsModals,
  TransactionsToastList,
  NotificationModal
} = DappUI;

export default function App() {
  const { search } = useLocation();
  
  const query = new URLSearchParams(search);
  const { referralCode, gateId, nftId, src, blockchain, variant } = Object.fromEntries(query);
  const paramsToPass= src=="nftaccess"?`?src=${src}&gateId=${gateId}&nftId=${nftId}&blockchain=${blockchain}&variant=${variant}`:"";
  console.log(paramsToPass);
  const text = src=="nftaccess"?"Verify with NFTcommerce":dAppName;

  React.useEffect(() => {
    // Setting referral code to local storage from url arguments
    console.log("code" + referralCode);
    const d = new Date();
    const seconds = d.getSeconds();
    console.log(seconds);
    if (referralCode) {localStorage.setItem('referralCode', referralCode);
    console.log("stroing");
  }
    // else localStorage.setItem('referralCode', 'undefined');
  }, []);
  return (
    <DappProvider environment={customNetworkConfig.id} customNetworkConfig={customNetworkConfig} >
      <ContextProvider>
    
        <Layout>
          
          
            <TransactionsToastList />
            <NotificationModal />
            <SignTransactionsModals className='custom-class-for-modals' />
            <Switch>
            <Route
              path={routeNames.unlock}
              component={() => (
                <UnlockPage
                  loginRoute={routeNames.dashboard+paramsToPass}
                  title={text}
                  description="Please choose a login method:"
                />
              )}
              exact={true}
            />

            {routes.map((route, i) => (
              <Route
                path={route.path}
                key={route.path + i}
                component={route.component}
                exact={true}
              />
            ))}
            <Route component={PageNotFoud} />
          </Switch>
        
        </Layout>
    
      </ContextProvider>
    </DappProvider>
  );
}
