import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { dAppName } from "config";
import { routeNames } from "routes";
import styled from "styled-components";

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;

  width: 140px;
  height: 56px;
  top: 0px;

  background: #FFFFFF;
  border-radius: 100px;

  order: 1;
  flex-grow: 0;
  margin: 0px 24px;
`;

const Home = () => {
  const { search } = useLocation();
  
  const query = new URLSearchParams(search);
  const { referralCode, gateId, nftId, src, blockchain, variant } = Object.fromEntries(query);
  const paramsToPass= src=="nftaccess"?`?src=${src}&gateId=${gateId}&nftId=${nftId}&blockchain=${blockchain}&variant=${variant}`:"";
  const text = src=="nftaccess"?"Verify with NFTaccess":dAppName;
  
  return (
    <div className="d-flex flex-fill align-items-center container">
        <div className="col-12 col-md-8 col-lg-5 mx-auto">
          <div className="card shadow-p rounded p-4 border-0">
            <div className="card-body d-flex flex-column align-items-center">
              <h2 className="mb-3 login-card-text-big" data-testid="title">
                {text}
              </h2>

              <h6 className="mb-3">
                <br /> Login using your Elrond wallet.
              </h6>
              
              <Link
                to={routeNames.unlock+paramsToPass}
                data-testid="loginBtn"
              >
                <LinkWrapper>
                  Login
                </LinkWrapper>
              </Link>

            </div>
          </div>
        </div>
      </div>
  );
};

export default Home;
