import { CustomNetworkType } from '@elrondnetwork/dapp-core';

export const dAppName = "uPark Minter";
export const nftCollectionIdentifier = "UGP-e71806";
export const nftCombeysCollectionIdentifier = "UGP-e71806";
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;

export const walletConnectBridge = "https://bridge.walletconnect.org";

export const contractAddress =
    "erd1qqqqqqqqqqqqqpgqa43cpj7tmjl2vef3dltx54c749azs94e6vvqsfyqp3";

// export const customNetworkConfig: CustomNetworkType = {
//   id: "testnet",
//   name: "Testnet",
//   chainId: "T",
//   egldLabel: "EGLD",
//   egldDenomination: "18",
//   decimals: "2",
//   gasPerDataByte: "1500",
//   walletConnectDeepLink: "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.main&link=https://maiar.com/",
//   walletAddress: "https://wallet.elrond.com",
//   apiAddress: "https://testnet-api.elrond.com",
//   explorerAddress: "https://testnet-api.elrond.com",
// };

export const customNetworkConfig: CustomNetworkType = {
  id: "mainnet",
  name: "Mainnet",
  chainId: "1",
  egldLabel: "EGLD",
  egldDenomination: "18",
  decimals: "2",
  gasPerDataByte: "1500",
  walletConnectDeepLink: "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.main&link=https://maiar.com/",
  walletAddress: "https://wallet.elrond.com",
  apiAddress: "https://api.elrond.com",
  explorerAddress: "https://api.elrond.com",
};


// export const nftCollectionIdentifier = "TEST-284ee9";

// export const contractAddress =
//   "erd1qqqqqqqqqqqqqpgq5n3pl6c8uzg79aasgd8vnr7j6ja9nq8zrvyqv2hvcr";

// export const network: Dapp.NetworkType = {
//   id: "devnet",
//   name: "Devnet",
//   egldLabel: "xEGLD",
//   walletAddress: "https://devnet-wallet.elrond.com",
//   apiAddress: "https://devnet-api.elrond.com",
//   gatewayAddress: "https://devnet-gateway.elrond.com",
//   explorerAddress: "http://devnet-explorer.elrond.com/",
// };
